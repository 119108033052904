let resumeData = {
  imagebaseurl: "https://rbhatia46.github.io/",
  name: "Xavier Mas Leszkiewicz",
  role: "Entrepreneur, Software Engineer, Teacher and Music Producer",
  linkedinId: "xavier-mas-leszkiewicz",
  skypeid: "xmasle.telecos",
  roleDescription:
    "\nA curious person who looks forward to learning things every day",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/xavier-mas-leszkiewicz/",
      className: "fa fa-linkedin",
    },
    {
      name: "github",
      url: "https://github.com/masosky",
      className: "fa fa-github",
    },
    {
      name: "twitter",
      url: "https://twitter.com/xmasle",
      className: "fa fa-twitter",
    },
    {
      name: "youtube",
      url: "https://www.youtube.com/channel/UCl6lQmCSE8LMItNFyOEw6Hw",
      className: "fa fa-youtube",
    },
    {
      name: "instagram",
      url: "https://instagram.com/xavyxmusic",
      className: "fa fa-instagram",
    }
  ],
  aboutme: `Extremly focused in finding solutions. Habits make the most of you. Entrepreneur, Software Engineer, Teacher and Music Producer`,
  address: "Barcelona",
  website: "https://relevanzz.com",
  education: [
    {
      UniversityName:
        "UPC School. School of Professionals & Executive Development",
      specialization: "Postgraduate in Quantum Engineering",
      MonthOfPassing: "May",
      YearOfPassing: "2023",
      // Achievements: "Some Achievements"
    },
    {
      UniversityName:
        "UPC School. School of Professionals & Executive Development",
      specialization: "Big Data Management & Analytics",
      MonthOfPassing: "July",
      YearOfPassing: "2016",
      // Achievements: "Some Achievements"
    },
    {
      UniversityName: "UPC. Universitat Politècnica de Catalunya",
      specialization: "Bachelor degree in Audiovisual Systems",
      MonthOfPassing: "March",
      YearOfPassing: "2015",
      // Achievements: "Some Achievements"
    },
    {
      UniversityName: "Technische Universität Berlin",
      specialization: "Bachelor Thesis",
      MonthOfPassing: "February",
      YearOfPassing: "2015",
      Achievements:
        "Object Tracking with multiple cameras using real-world coordinates",
    },
  ],
  work: [
    {
      CompanyName: "Lumenalta",
      specialization: "Senior Full-Stack Engineer",
      MonthOfLeaving: "",
      YearOfLeaving: "",
      Achievements: `Solving and implementing technical solutions as consultant. Designing software architectures for frontend and backend using multiple technologies as React, Ionic, NodeJs, SQL, AWS, GCP`,
    },
    {
      CompanyName: "Elliptic",
      specialization: "Senior Full-Stack Engineer",
      MonthOfLeaving: "January",
      YearOfLeaving: "2023",
      Achievements: `Developing the UI and the backend for the crypto world.
      This App allows the user to navigate through the blockchains (Bitcoin, Ethereum...) and analyze different transactions in a really dynamic and visual presentation`,
    },
    {
      CompanyName: "Full on Net (Telefonica)",
      specialization: "Senior Software Engineer",
      MonthOfLeaving: "May",
      YearOfLeaving: "2022",
      Achievements: `Merge LATAM and Spain infrastructure for all IPTV events.
      Technologies: Python, Java, MongoDB, Kafka, Kafka Streams, Celery, Behave`,
    },
    {
      CompanyName: "Relevanzz",
      specialization: "Co-Founder and CTO",
      MonthOfLeaving: "July",
      YearOfLeaving: "2020",
      Achievements: `Working as a CTO and Co-Founder gave me a big picture of how to run a company. As a Founder, I am working in all areas of business, marketing, sales and technology.
      My main role is to design and implement the software product that we offer. My daily work involves product design (UI/UX), infrastructure and developing.`,
    },
    {
      CompanyName: "eDreams ODIGEO",
      specialization: "Software Engineer - Machine Learning POD",
      MonthOfLeaving: "July",
      YearOfLeaving: "2019",
      Achievements: `Working as a senior software engineer at Machine Learning Team at eDreams Odigeo.
      My goal is to design and provide a robust back-end infrastructure capable to serve different Machine Learning technologies.
      I am working close in Data Science and providing the latest technology for my company.
      Programming with Java, Python, Docker, Maven, Kafka, Kubernetes, Kibana, Elastic Search and related technologies.`,
    },
    {
      CompanyName: "eDreams ODIGEO",
      specialization: "Java Developer - BI Revamp",
      MonthOfLeaving: "February",
      YearOfLeaving: "2018",
      Achievements: `Working with Java and all related technologies as NoSQL, SQL, Maven, and other technologies. 
      My goal was to treat a big amount of data, treat this data and finally store it.
      I had complete ownership with this project, since designing until deploying to production.`,
    },
    {
      CompanyName: "SIA Group. Sistemas Informáticos Abiertos",
      specialization: "Java Developer and IT Security",
      MonthOfLeaving: "Jan",
      YearOfLeaving: "2017",
      Achievements: `Working in a government environment involving a wide variety of technologies and different operating systems. Managing more than 250,000 identities and Sign-On protection for these identities.
      Responsible for migration of many applications and responsible for improving or creating scripts using Oracle Identity Manager
      Mostly focused on Java applications and similar. Dealing with many technologies like Active Directory, Oracle database, Visual Basic, Oracle Identity Manager, Siteminder (SSO) and frameworks as Spring or Struts.
      `,
    },
  ],
  skillsDescription: "My most outstanding skills",
  skills: [
    {
      skillname: "Software Engineering",
    },
    {
      skillname: "People Oriented",
    },
    {
      skillname: "Motivator",
    },
    {
      skillname: "Amiable profile",
    },
  ],
  portfolio: [
    {
      name: "project1",
      description: "mobileapp",
      imgurl: "images/portfolio/phone.jpg",
    },
    {
      name: "project2",
      description: "mobileapp",
      imgurl: "images/portfolio/project.jpg",
    },
    {
      name: "project3",
      description: "mobileapp",
      imgurl: "images/portfolio/project2.png",
    },
    {
      name: "project4",
      description: "mobileapp",
      imgurl: "images/portfolio/phone.jpg",
    },
  ],
  testimonials: [
    {
      description: "This is a sample testimonial",
      name: "Some technical guy",
    },
    {
      description: "This is a sample testimonial",
      name: "Some technical guy",
    },
  ],
  aboutXavyx: "I am an amateur music producer who enjoys creating Deep House tracks. I am super excited to collaborate with different producers to learn and improve my skills.",
  youTubeChannel: "https://www.youtube.com/channel/UCl6lQmCSE8LMItNFyOEw6Hw",
  soundCloud: "https://soundcloud.com/xavyx-music/",
  xavyxInstagram: "https://instagram.com/xavyxmusic",
  xavyxSpotify: "https://open.spotify.com/artist/6XQEl2BobkH08FyQzQruqP?si=6nQR0-4eRaesGyZp6XV9nQ&dl_branch=1",
};

export default resumeData;
